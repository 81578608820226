console.log("Base is working!");

const videos = document.querySelectorAll(".gallery__play");

if (videos) {
  videos.forEach((video) => {
    video.addEventListener("click", () => {
      const targetVideo = document.getElementById(video.dataset.video);
      targetVideo.play();
      targetVideo.controls = true;
      video.style.display = "none";
    });
  });
}

// ________________________________________

const slider = document.querySelectorAll(".slider");

slider?.forEach((slider) => {
  const sliderWrapper = slider.querySelector(".slider__wrapper");
  const sliderContainer = slider.querySelector(".slider__container");
  const sliderNav = slider.querySelector(".slider__nav");
  let slidePrev;
  let slideNext;

  const itemWidth =
    sliderContainer?.querySelector(".slider__item")?.offsetWidth;

  if (sliderNav) {
    slidePrev = sliderNav.querySelector(".slider__prev");
    slideNext = sliderNav.querySelector(".slider__next");
  }

  const watchNavState = () => {
    if (sliderContainer.scrollLeft === 0) {
      slidePrev.classList.remove("active");
    } else {
      slidePrev.classList.add("active");
    }
    if (
      sliderContainer.scrollLeft + sliderWrapper.offsetWidth >=
      sliderContainer.scrollWidth
    ) {
      slideNext.classList.remove("active");
    } else {
      slideNext.classList.add("active");
    }
  };

  watchNavState();

  if (slideNext) {
    slideNext.addEventListener("click", () => {
      watchNavState();
      if (
        sliderContainer.scrollLeft + sliderWrapper.offsetWidth <
        sliderContainer.scrollWidth
      ) {
        sliderContainer.scrollLeft += itemWidth * 1.5;
      } else {
      }
    });
  }
  if (slidePrev) {
    slidePrev.addEventListener("click", () => {
      watchNavState();
      if (
        sliderContainer.scrollLeft - sliderWrapper.offsetWidth <
        sliderContainer.scrollWidth
      ) {
        sliderContainer.scrollLeft -= itemWidth * 1.5;
      } else {
      }
    });
  }
});

//--------------------Intersection observer

const animateTarget = document.querySelectorAll(".animate-observe");
const options = {};

const animateTargetObserver = new IntersectionObserver(function (
  entries,
  observer
) {
  entries.forEach(function (entry) {
    if (entry.isIntersecting) {
      entry.target.classList.add("animate--reveal");
    } else {
      // entry.target.classList.remove("animate--reveal");
    }
  });
},
options);

animateTarget.forEach(function (animateTarget) {
  animateTargetObserver.observe(animateTarget);
});
